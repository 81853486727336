const required = (propertyType: string, customErrorMessage: string) => {
  return (v: string ) => (v && v.length > 0) || customErrorMessage || `Bạn phải điền dữ liệu vào ${propertyType}`
}
const emailFormat = () => {
  const regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
  return (v: string) => (v && regex.test(v)) || 'Thông tin email không hợp lệ !'
}
export default {
  required,
  emailFormat,
}